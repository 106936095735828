import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/jz/pksxx',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/jz/pksxx/' + id,
    method: 'delete'
  })
}

export function delJtcy(id) {
  return request({
    url: 'api/jz/jtcy/' + id,
    method: 'delete'
  })
}

export function delZzqk(id) {
  return request({
    url: 'api/jz/zzqk/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/jz/pksxx',
    method: 'put',
    data
  })
}

export function save(data) {
  return request({
    url: 'api/jz/pksxxSave',
    method: 'put',
    data
  })
}


export function getPksxxById(params) {
  return request({
    url: 'api/jz/pksxxById',
    method: 'get',
    params
  })
}

export function exportPdf(id) {
  return request({
    url: 'api/jz/pksxx/exportPdf/'+id,
    method: 'get',
    responseType: 'blob'
  })
}

export default { add, del,delJtcy,delZzqk,edit,save,getPksxxById,exportPdf }

