import request from '@/utils/request'

//学生本人查询
export function getStuinfo(params) {
  return request({
    url: 'api/xj/stuinfo',
    method: 'get',
    params
  })
}

export function getLoanInfo(params) {
  return request({
    url: 'api/homeStu/getLoanInfo',
    method: 'get',
    params
  })
}

export function getUnslInfo(params) {
  return request({
    url: 'api/homeStu/getUnslInfo',
    method: 'get',
    params
  })
}

export function getRoom(params) {
  return request({
    url: 'api/homeStu/getRoomInfo',
    method: 'get',
    params
  })
}

//非学生本人查询
export function getStuinfoNotPage(params) {
  return request({
    url: 'api/xj/stuinfos/NotPage',
    method: 'get',
    params
  })
}

export function settingClassInfo(data) {
  return request({
    url: 'api/xj/stuClass/setting',
    method: 'post',
    data
  })
}

export default { settingClassInfo,getStuinfoNotPage}
