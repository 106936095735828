<template>
  <div id="FamilyForm">
    <!-- 表单 -->
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" placeholder="请填写姓名" :disabled="true" label="姓名" />
        <van-field v-model="form.stuNo" placeholder="请填写学号" :disabled="true" label="学号" />
        <van-field v-model="form.gradeNo" placeholder="请填写年级" :disabled="true" label="年级" />
        <van-field v-model="form.unitName" placeholder="请填写院系" :disabled="true" label="院系" />
        <van-field v-model="form.specName" placeholder="请填写专业" :disabled="true" label="专业" />
        <van-field v-model="form.className" placeholder="请填写班级" :disabled="true" label="班级" />
        <custom-radio v-model="form.sexName" label="性别" :disabled="true" valueKey="label" :columns="sexDict" />
        <van-field v-model="form.enterMon" placeholder="请填写入学年月" :disabled="true" label="入学年月" />
        <van-field v-model="form.cardNo" placeholder="请填写身份证号" :disabled="true" label="身份证号" />
        <custom-date-picker required ref="bdDate" v-model="form.bdDate" label="出生日期" placeholder="点击选择出生日期" />
        <regional-cascader required ref="nativePlaceNo" v-model="form.nativePlaceNo" placeholder="请选择籍贯" :changeLevel="2" label="籍贯" />
        <van-field :rules="[{ required: true}]" required v-model="form.famCnt" placeholder="请填写家庭人口数" label="家庭人口数" />
        <van-field :rules="[{ required: true, message: '请填写电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" required v-model="form.telNo" placeholder="请填写电话" label="联系电话" />
      </van-cell-group>
      <!-- 家庭信息 -->
      <van-cell-group title="家庭信息">
        <regional-cascader ref="addressNo" required v-model="form.addressNo" placeholder="请填写家庭地址" label="家庭地址" />
        <van-field :rules="[{ required: true, message: '请填写详细地址' }]" required v-model="form.homeAddress" placeholder="请填写详细地址" label="详细地址" />
        <van-field v-model="form.homePostcode" placeholder="请填写邮政编码" label="邮政编码" />
        <van-field v-model="form.mobileOfParent1" placeholder="请填写家长手机号1" label="家长手机号1" required :rules="[{ required: true, message: '请填写家长手机号1' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" />
        <van-field v-model="form.mobileOfParent2" placeholder="请填写家长手机号2" label="家长手机号2" />
        <custom-select ref="housingNo" required v-model="form.housingNo" placeholder="请选择住房情况" label="住房情况" :columns="dict.xj_zfqk" />
        <van-field v-model="form.housingText" v-if="form.housingNo === '99'" placeholder="请填写住房情况备注" label="住房情况备注" />
        <custom-select ref="carPurchaseNo" required v-model="form.carPurchaseNo" placeholder="请选择购车情况" label="购车情况" :columns="dict.xj_gcqk" />
        <van-field v-model="form.carPurchaseText" v-if="form.carPurchaseNo === '99'" placeholder="请填写购车情况备注" label="购车情况备注" />
      </van-cell-group>
      <!-- 家庭成员情况 -->
      <van-cell-group title="家庭成员情况">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.jtcyxxList" :key="index" :title="item.name+'('+item.relation+')'" :name="index">
            <div> <span class="collapse-title">身份证号码:</span><span class="collapse-text">{{item.cardNo}}</span></div>
            <div> <span class="collapse-title">工作单位:</span><span class="collapse-text">{{item.unitName}}</span></div>
            <div> <span class="collapse-title">职业:</span><span class="collapse-text">{{item.occupation}}</span></div>
            <div> <span class="collapse-title">收入:</span><span class="collapse-text">{{item.annualIncome}}</span></div>
            <div> <span class="collapse-title">健康状态:</span><span class="collapse-text">{{codeConvertLabel(item.healthNo,dict.ctry_base_health_stat)}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="familyDelete(index,item.id)">删除</van-button>
            <van-button style="height:26px;margin-left: 5px;margin-top: 10px;" round type="primary" plain hairline @click="familyEdit(item,index)">编辑</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" native-type="button" size="small" style="height:36px" @click="familyAdd">添加家庭成员</van-button>
        </div>
      </van-cell-group>
      <!-- 特殊群体类型 -->
      <van-cell-group title="特殊群体类型">
        <custom-radio v-model="form.needyStu" class="radio-other-info" label="建档立卡贫户" :columns="dict.xj_sf" />
        <custom-radio v-model="form.destituteStu" class="radio-other-info" label="特困供养学生" :columns="dict.xj_sf" />
        <custom-radio v-model="form.orphan" class="radio-other-info" label="孤儿" :columns="dict.xj_sf" />
        <custom-radio v-model="form.martyrStu" class="radio-other-info" label="烈士子女" :columns="dict.xj_sf" />
        <custom-radio v-model="form.deformityStu" class="radio-other-info" label="残疾学生及残疾人子女" :columns="dict.xj_sf" />
        <custom-radio v-model="form.safeguardStu" class="radio-other-info" label="最低生活保障家庭" :columns="dict.xj_sf" />
        <custom-select v-model="form.other" class="radio-other-info" placeholder="请选择其他原因" label="其他原因" :columns="dict.xj_tsqtyy" />
      </van-cell-group>
      <!-- 影响家庭经济状况有关信息 -->
      <van-cell-group title="影响家庭经济状况有关信息" class="family-yxjjzk-cell">
        <div class="form-label required" style="padding-top: 10px;">人均年收入（元）</div>
        <van-field v-model="form.annualIncome" :rules="[{ required: true }]" placeholder="请填写人均年收入（元）" />
        <div class="form-label required">家庭遭受自然灾害情况</div>
        <van-field v-model="form.annualDisastersText" :rules="[{ required: true }]" placeholder="请填写家庭遭受自然灾害情况" />
        <div class="form-label required">家庭遭受突发意外事件</div>
        <van-field v-model="form.accidentText" :rules="[{ required: true }]" placeholder="请填写家庭遭受突发意外事件" />
        <div class="form-label required">家庭成员因残疾、年迈而劳动能力弱情况 </div>
        <van-field v-model="form.deformity" :rules="[{ required: true }]" placeholder="请填写家庭成员因残疾、年迈而劳动能力弱情况" />
        <div class="form-label required">家庭成员失业情况 </div>
        <van-field v-model="form.unemployedText" :rules="[{ required: true }]" placeholder="请填写家庭成员失业情况" />
        <div class="form-label required">家庭欠债情况 </div>
        <van-field v-model="form.debtDueText" :rules="[{ required: true }]" placeholder="请填写家庭欠债情况" />
        <div class="form-label required">其他情况 </div>
        <van-field v-model="form.otherText" :rules="[{ required: true }]" placeholder="请填写其他情况" />
      </van-cell-group>
      <!-- 学生在校期间受资助情况 -->
      <van-cell-group title="学生在校期间受资助情况">
        <van-collapse v-model="activeName2s">
          <van-collapse-item v-for="item,index in form.zxzzqkList" :key="index" :title="item.year+'年'" :value="item.subsidize ?'¥ '+item.subsidize:''" :name="index">
            <div> <span class="collapse-title">受资助项目:</span><span class="collapse-text">{{item.funPro}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="zzxmDelete(index,item.id)">删除</van-button>
            <van-button style="height:26px;margin-left: 5px;margin-top: 10px;" round type="primary" plain hairline @click="zzxmEdit(item,index)">编辑</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" size="small" native-type="button" style="height:36px" @click="zzxmAdd">添加受资助情况</van-button>
        </div>
      </van-cell-group>
      <!-- 其他信息 -->
      <van-cell-group title="其他信息">
        <van-field v-model="form.promise" rows="3" class="textarea-style" style="margin-bottom:76px;" arrow-direction="" label="个人承诺" type="textarea" maxlength="300" placeholder="请填写个人承诺" show-word-limit />
      </van-cell-group>

      <div class="submit-button  shadow-style" v-if="form.status === 1 || form.status === '1'">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit(true)">提 交</van-button>
        </div>
      </div>
      <div class="submit-save  shadow-style" v-else>
        <van-button round block type="info" class="submit-save-savebtn" color="rgba(235, 123, 77, 1)" :loading="loading" @click="doSubmit(false)">保 存</van-button>
        <van-button round block type="primary" class="submit-save-subbtn" :loading="loading" @click="doSubmit(true)">提 交</van-button>
      </div>
    </van-form>

    <!-- 家庭成员信息弹出层 -->
    <van-popup v-model="familyInfoShow" position="bottom" closeable @close="familyclose" :style="{ height: ' 518px' }">
      <div class="van-popup-title-div"><b>{{isFamilyAdd?'新增家庭成员':'编辑家庭成员'}}</b></div>
      <van-form @submit="familySubmit">
        <van-field required v-model="familyForm.name" placeholder="请填写姓名" label="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field required v-model="familyForm.cardNo" placeholder="请填写身份证号" label="身份证号" :rules="[{ required: true, message: '请填写身份证号' },
        {pattern:cardReg, message: '请输入合法的身份证号'}]" />
        <van-field v-model="familyForm.age" placeholder="请填写年龄" label="年龄" required :rules="[{ required: true, message: '请填写姓名' },
        {
          pattern:/^[+]{0,1}(\d+)$/,message: '请输入合法的年龄'
        }]" />
        <van-field v-model="familyForm.relation" placeholder="请填写与本人关系" required label="与本人关系" :rules="[{ required: true, message: '请填写与本人关系' }]" />
        <van-field v-model="familyForm.unitName" placeholder="请填写工作单位" required :rules="[{ required: true, message: '请填写工作单位' }]" label="工作单位" />
        <van-field v-model="familyForm.occupation" placeholder="请填写职业" required :rules="[{ required: true, message: '请填写职业' }]" label="职业" />
        <van-field v-model="familyForm.annualIncome" placeholder="请填写收入" label="收入(元)" :rules="[{
          pattern:/^[+]{0,1}(\d+)$|^$/,message: '请输入合法的收入'
        }]" />
        <custom-select v-model="familyForm.healthNo" placeholder="请选择健康状态" label="健康状态" :columns="dict.ctry_base_health_stat" />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" style="font-size:15px">保 存</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 资助项目信息弹出层 -->
    <van-popup v-model="zzxmInfoShow" position="bottom" closeable @close="zzxmClose" :style="{ height: ' 323px' }">
      <div class="van-popup-title-div"><b>{{isZzxmAdd?'新增资助项目':'编辑资助项目'}}</b></div>
      <van-form @submit="zzxmSubmit">
        <div v-if="zzxmForm.type ==='DQ'" style="color: #646566;margin: 10px 0 10px 16px;">本年度拟申请资助项目</div>
        <custom-select v-else v-model="zzxmForm.year" placeholder="请选择年度" label="年度" :columns="dict.xj_nj" />
        <van-field v-model="zzxmForm.funPro" rows="2" class="textarea-style" :rules="[{ required: true }]" arrow-direction="" label="资助项目" type="textarea" maxlength="150" placeholder="请填写资助项目" show-word-limit />
        <van-field v-model="zzxmForm.subsidize" placeholder="请填资助金额" :rules="[{ required: true }]" label="资助金额" />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" style="font-size:15px">保 存</van-button>
        </div>
      </van-form>
    </van-popup>

  </div>
</template>

<script>
import { Dialog } from "vant";
import { Notify } from "vant";
import { mapGetters } from "vuex";
import { validCustom } from "@/utils";
import {
  save,
  delJtcy,
  delZzqk,
  getPksxxById,
} from "@/api/modules/student/pksxx";
import { getStuinfoNotPage, getStuinfo } from "@/api/modules/student/stuInfo";

export default {
  name: "FamilyForm",
  components: {},
  dicts: [
    "xj_zfqk",
    "xj_gcqk",
    "xj_xb",
    "xj_sf",
    "xj_tsqtyy",
    "xj_nj",
    "ctry_base_health_stat",
  ],
  data() {
    return {
      sexDict: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      isNotDict: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      showtext: "",
      // 家庭信息弹出层列表默认展开第几个
      activeNames: [0],
      activeName2s: [0],
      form: {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别码
        sexCode: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 家庭地址代码
        addressNo: "",
        // 家庭地址名称
        addressName: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        homePostcode: "",
        // 家长手机号1
        mobileOfParent1: "",
        // 家长手机号1
        mobileOfParent2: "",
        // 住房情况代码
        housingNo: "01",
        // 住房情况名称
        housingName: "",
        // 住房情况备注
        housingText: "",
        // 购车情况代码
        carPurchaseNo: "01",
        // 购车情况名称
        carPurchaseName: "",
        // 购车情况备注
        carPurchaseText: "",
        // 建档立卡贫困家庭学生
        needyStu: "0",
        // 特困供养学生
        destituteStu: "0",
        // 孤儿
        orphan: "0",
        // 最低生活保障家庭学生
        safeguardStu: "0",
        // 烈士子女
        martyrStu: "0",
        // 家庭经济困难残疾学生及残疾人子女
        deformityStu: "0",
        // 其他原因
        other: "04",
        // 人均年收入
        annualIncome: "",
        // 家庭遭受自然灾害情况
        annualDisastersText: "",
        // 家庭遭受突发意外事件
        accidentText: "",
        // 家庭成员因残疾、年迈而劳动能力弱情况
        deformity: "",
        // 家庭成员失业情况
        unemployedText: "",
        // 家庭欠债情况
        debtDueText: "",
        // 其他情况
        otherText: "",
        // 个人承诺
        promise: "",
        // 评议状态代码
        reviewNo: "",
        // 评议状态名称
        reviewName: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        zxzzqkList: [],
        status: 0,
      },
      customRuleFileds: ["bdDate", "nativePlaceNo", "addressNo"],
      stuInfo: {},
      params: {},
      //家庭成员
      familyForm: {
        id: "",
        name: "",
        cardNo: "",
        age: "",
        relation: "",
        unitName: "",
        occupation: "",
        healthNo: "",
        annualIncome: "",
        health: "",
        typeNo: "PKSXX",
      },
      familyInfoShow: false,
      isFamilyAdd: false,
      editIndex: 0,
      showErr: false,
      loading: false,
      cardReg:
        /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
      // 资助项目
      isZzxmAdd: false,
      zzxmInfoShow: false,
      editZzxmIndex: 0,
      zzxmForm: {
        id: "",
        year: "",
        funPro: "",
        subsidize: "",
        pksxxid: "",
        type: "LS",
      },
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  methods: {
    codeConvertLabel(code, dicts) {
      if (code) {
        return dicts
          .filter((item) => {
            return item.value + "" === code + "";
          })
          .map((item) => {
            return item.label;
          })
          .toString();
      }
      return "";
    },
    initData() {
      this.form.stuNo = this.user.username;
      if (this.$route.query && this.$route.query.id) {
        document.title = "编辑家庭情况调查表";
        //学生修改
        getPksxxById({ id: this.$route.query.id }).then((res) => {
          this.form = res;
        });
      } else {
        this.showtext = "";
        document.title = "家庭情况调查表填报";
        //学生新增
        getStuinfo({ stuNo: "" }).then((stu) => {
          if (stu) {
            this.updateFrom(stu);
          } else {
            this.$message.error("未检索到您的学生信息！");
            return;
          }
        });
      }
      this.form.stuNo = this.user.username;
      //   this.initForm();
    },
    doSubmit(status) {
      if (status) {
        // 提交
        this.$refs.form.validate().then(
          (res) => {
            if (validCustom(this.customRuleFileds, this)) {
              // 验证通过数据提交
              Dialog.confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
                .then(() => {
                  this.form.status = 1;
                  this.toSave(this.form);
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              this.$notify({
                type: "warning",
                message: "有提交项未填写！",
                duration: 1500,
              });
            }
          },
          (err) => {
            validCustom(this.customRuleFileds, this);
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        );
      } else {
        // 保存
        Dialog.confirm({
          title: "提示",
          message: "你确定要进行保存操作吗？",
        })
          .then(() => {
            this.loading = true;
            this.form.status = 0;
            this.toSave(this.form);
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    toSave(form) {
      this.loading = true;
      save(form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.onClickLeft();
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    updateFrom(stuInfo) {
      //院系所号
      this.form.unitNo = stuInfo.collNo; // 院系代码
      this.form.unitName = stuInfo.collName; // 院系名称
      this.form.specCode = stuInfo.sqecCode; // 专业代码
      this.form.specName = stuInfo.sqecName; // 专业名称
      this.form.gradeNo = stuInfo.gradeNo; // 年级
      this.form.classNo = stuInfo.classNo; // 班级号
      this.form.className = stuInfo.className; // 班级名称
      this.form.classTchStaffNo = stuInfo.classTchStaffNo; // 班主任工号
      this.form.classTchStaffName = stuInfo.classTchStaffName; // 班主任姓名
      this.form.conslrStaffNo = stuInfo.conslrStaffNo; // 辅导员号
      this.form.conslrStaffName = stuInfo.conslrStaffName; // 辅导院姓名
      this.form.enterMon = stuInfo.enterMon; // 入学年月
      this.form.stuNo = stuInfo.stuNo; // 学号
      this.form.stuName = stuInfo.stuName; // 姓名
      this.form.sexCode = stuInfo.sexCode; // 性别码
      this.form.sexName = stuInfo.sexName; // 性别名称
      this.form.bdDate = stuInfo.bdDate; // 出生日期
      this.form.nativePlaceNo = stuInfo.nativePlaceNo; // 籍贯地码
      this.form.nativePlace = stuInfo.nativePlace; // 籍贯地码
      this.form.cardNo = stuInfo.cardNo; // 身份证号
      this.form.schName = stuInfo.schName; // 学校
      this.form.schCode = stuInfo.schCode; // 学校
    },
    // 资助项目
    zzxmDelete(index, id) {
      if (id) {
        delZzqk(id).then((res) => {
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2000,
          });
          this.form.zxzzqkList.splice(index, 1);
        });
      } else {
        this.form.zxzzqkList.splice(index, 1);
      }
    },
    zzxmSubmit() {
      if (this.isZzxmAdd) {
        this.form.zxzzqkList.push(this.zzxmForm);
      } else {
        this.form.zxzzqkList.splice(this.editIndex, 1, this.zzxmForm);
      }
      this.zzxmClose();
    },
    zzxmAdd() {
      this.isZzxmAdd = true;
      this.zzxmInfoShow = true;
    },
    zzxmEdit(data, index) {
      this.zzxmForm = data;
      this.editZzxmIndex = index;
      this.isZzxmAdd = false;
      this.zzxmInfoShow = true;
    },
    zzxmClose() {
      this.zzxmInfoShow = false;
      this.zzxmForm = {
        id: "",
        year: "",
        funPro: "",
        subsidize: "",
        pksxxid: "",
        type: "LS",
      };
    },
    // ------家庭信息
    familySubmit() {
      this.showErr = false;
      this.familyForm.health = this.codeConvertLabel(
        this.familyForm.healthNo,
        this.dict.ctry_base_health_stat
      );
      if (this.isFamilyAdd) {
        this.form.jtcyxxList.push(this.familyForm);
      } else {
        this.form.jtcyxxList.splice(this.editIndex, 1, this.familyForm);
      }
      this.familyclose();
    },
    familyAdd() {
      this.isFamilyAdd = true;
      this.familyInfoShow = true;
    },
    familyEdit(data, index) {
      this.familyForm = data;
      this.editIndex = index;
      this.isFamilyAdd = false;
      this.familyInfoShow = true;
    },
    familyDelete(index, id) {
      if (id) {
        delJtcy(id).then((res) => {
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2000,
          });
          this.form.jtcyxxList.splice(index, 1);
        });
      } else {
        this.form.jtcyxxList.splice(index, 1);
      }
    },
    familyclose() {
      this.familyInfoShow = false;
      this.familyForm = {
        id: "",
        name: "",
        cardNo: "",
        age: "",
        relation: "",
        unitName: "",
        occupation: "",
        healthNo: "",
        annualIncome: "",
        health: "",
        typeNo: "PKSXX",
      };
    },
    onClickLeft() {
      this.form = {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别码
        sexCode: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 家庭地址代码
        addressNo: "",
        // 家庭地址名称
        addressName: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        homePostcode: "",
        // 家长手机号1
        mobileOfParent1: "",
        // 家长手机号1
        mobileOfParent2: "",
        // 住房情况代码
        housingNo: "01",
        // 住房情况名称
        housingName: "",
        // 住房情况备注
        housingText: "",
        // 购车情况代码
        carPurchaseNo: "01",
        // 购车情况名称
        carPurchaseName: "",
        // 购车情况备注
        carPurchaseText: "",
        // 建档立卡贫困家庭学生
        needyStu: "0",
        // 特困供养学生
        destituteStu: "0",
        // 孤儿
        orphan: "0",
        // 最低生活保障家庭学生
        safeguardStu: "0",
        // 烈士子女
        martyrStu: "0",
        // 家庭经济困难残疾学生及残疾人子女
        deformityStu: "0",
        // 其他原因
        other: "04",
        // 人均年收入
        annualIncome: "",
        // 家庭遭受自然灾害情况
        annualDisastersText: "",
        // 家庭遭受突发意外事件
        accidentText: "",
        // 家庭成员因残疾、年迈而劳动能力弱情况
        deformity: "",
        // 家庭成员失业情况
        unemployedText: "",
        // 家庭欠债情况
        debtDueText: "",
        // 其他情况
        otherText: "",
        // 个人承诺
        promise: "",
        // 评议状态代码
        reviewNo: "",
        // 评议状态名称
        reviewName: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        zxzzqkList: [
          {
            id: "",
            year: new Date().getFullYear().toString(),
            funPro: "",
            subsidize: "",
            pksxxid: "",
            type: "DQ",
          },
        ],
        status: 0,
      };
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/student/family.scss';
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}
::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  text-align: center;
  // &-savebtn {
  //   margin-left: -5%;
  // }

  &-subbtn {
    margin-left: 5%;
  }

  .van-button {
    margin-top: 10px;
    margin-bottom: 9px;
    width: 40%;
  }

  .van-button--block {
    display: inline-block !important;
  }
}
</style>